/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #0077b6; /* Blue background */
  color: white;
  min-height: 100vh;
  box-sizing: border-box; /* Ensure consistent padding and borders */
}

/* Navigation Bar */
nav {
  background-color: #023e8a;
  padding: 15px 0;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  transition: color 0.3s;
}

nav ul li a:hover {
  text-decoration: underline;
  color: #caf0f8;
}

/* Home Page Styling */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); /* Subtract navbar height */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.home h1 {
  font-size: 3.5rem;
  margin-bottom: 15px;
  color: #ffffff;
}

.home p {
  font-size: 1.4rem;
  margin-bottom: 25px;
  color: #e0f7fa;
}

/* CTA Button Group */
.cta-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.cta-buttons button {
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  background-color: #00b4d8;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-buttons button:hover {
  background-color: #0077b6;
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

/* Footer Styles */
footer {
  background-color: #023e8a;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* Form Styling */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Take full width */
  max-width: 400px; /* Center form */
  margin: 0 auto;
  margin-top: 20px;
}

/* Input Fields and Dropdown Styling */
input, select {
  margin-bottom: 15px;
  padding: 12px;
  width: 100%; /* Full width inside form */
  max-width: 320px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Dropdown Custom Styles */
select {
  border: 1px solid #0077b6;
  background-color: white;
  color: #333;
  cursor: pointer;
  appearance: none; /* Removes native arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' fill='%230077b6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

select:focus {
  outline: none;
  border-color: #00b4d8;
  box-shadow: 0 0 5px rgba(0, 180, 216, 0.5);
}

/* Textarea Styling */
textarea {
  margin-bottom: 15px;
  padding: 12px;
  width: 100%;
  max-width: 320px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: none; /* Disable manual resizing */
}

textarea:focus {
  outline: none;
  border-color: #00b4d8;
  box-shadow: 0 0 5px rgba(0, 180, 216, 0.5);
}

/* Experience Buttons */
.experience-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
  margin-bottom: 15px;
}

.experience-buttons button {
  flex: 1;
  margin: 0 5px;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #0077b6;
  background-color: white;
  color: #0077b6;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.experience-buttons button.active {
  background-color: #0077b6;
  color: white;
}

.experience-buttons button:hover {
  background-color: #00b4d8;
  color: white;
}

/* Register Button */
button[type="submit"] {
  width: 100%;
  max-width: 320px;
  margin-top: 10px;
  padding: 15px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  background-color: #00b4d8;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0077b6;
  transform: translateY(-2px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

/* Login Page Styling */
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px); /* Adjust for navbar height */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Login Button */
.login-page button {
  margin-top: 15px;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  background-color: #00b4d8;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-page button:hover {
  background-color: #0077b6;
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}



/* Products Page Styling */
.products-page {
  padding: 50px 20px;
  text-align: center;
}

.products-page h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #fff;
}

/* Product Section */
.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;
  flex-wrap: wrap; /* Ensure responsiveness on smaller screens */
}

.product img {
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin: 10px;
}

.product-info {
  max-width: 500px;
  text-align: left;
  margin: 10px;
}

.product-info h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #00b4d8;
}

.product-info p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #e0f7fa;
}
